import { Routes, Route } from "react-router-dom";
import Dashboard from "./page/dashboard";
import Account from "./page/account";
import Layout from "./page/Layout";
import Login from "./page/login";
import RobloxSuccess from "./page/success";


function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/account" element={<Account />}/>
        <Route path="/roblox/success" element={<RobloxSuccess/>}/>
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}

export default App;
