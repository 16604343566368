import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
function Account() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get('https://theunknowanimegame.xyz/auth/user/me', { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }

    fetchUser();
  }, []);

  return (
    <div className="flex flex-col items-center p-6">
      <h1 className="text-2xl font-bold">Account Page</h1>

      {user ? (
        <div className="mt-4 w-full max-w-md shadow-lg rounded-lg p-6 flex flex-col items-center">
          <img
            src={user.avatar ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png` : 'https://via.placeholder.com/100'}
            alt="Discord Avatar"
            className="w-24 h-24 rounded-full border border-gray-300"
          />
          <div className="mt-4 text-lg">
            <p><strong>Discord ID:</strong> {user.discord_id || "N/A"}</p>
            <p><strong>Discord Name:</strong> {user.discord_name || "N/A"}</p>
            <p><strong>Roblox Username:</strong> {user.roblox_username || "Not linked"}</p>
            <p><strong>Roblox Nickname:</strong> {user.nickname || "Not linked"}</p>
          </div>
          {/* Green box for linking Roblox account */}
          <StyledButton class="fancy" href="https://theunknowanimegame.xyz/auth/roblox">
            <span class="top-key"></span>
            <span class="text">Link Roblox Account</span>
            <span class="bottom-key-1"></span>
            <span class="bottom-key-2"></span>
          </StyledButton>

        </div>
      ) : (
        <p className="mt-4">Loading user information...</p>
      )}

      <Link to="/" className="mt-6 text-blue-500 underline">Go to Home</Link>
    </div>
  );
}
const StyledButton = styled.a`
 background-color: transparent;
 border: 2px solid #000;
 border-radius: 0;
 box-sizing: border-box;
 color: #fff;
 cursor: pointer;
 display: inline-block;
 font-weight: 700;
 letter-spacing: 0.05em;
 margin: 0;
 outline: none;
 overflow: visible;
 padding: 1.25em 2em;
 position: relative;
 text-align: center;
 text-decoration: none;
 text-transform: none;
 transition: all 0.3s ease-in-out;
 user-select: none;
 font-size: 13px;

 &::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: black;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
 }

 .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
 }

 .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
 }

 .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
 }

 .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
 }

 &:hover {
  color: white;
  background: #CCEEBC;
 }

 &:hover::before {
  width: 0.9375rem;
  background: white;
 }

 &:hover .text {
  color: white;
  padding-left: 1.5em;
 }

 &:hover .top-key {
  left: -2px;
  width: 0px;
 }

 &:hover .bottom-key-1,
 &:hover .bottom-key-2 {
  right: 0;
  width: 0;
 }
`;

export default Account;
