import { Link } from "react-router-dom";

export default function RobloxSuccess() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
        {/* Green checkmark icon */}
        <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-500">
          <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        
        <h1 className="text-2xl font-bold mt-4">Successfully Linked!</h1>
        <p className="text-gray-600 mt-2">Your Roblox account has been linked successfully.</p>

        {/* Go back button */}
        <Link to="/account" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition">
          Go to Account
        </Link>
      </div>
    </div>
  );
}
