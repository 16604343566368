

export default function Dashboard() {
  const newsItems = [
    {
      id: 1,
      title: "Chào Cờ",
      content: "Test",
      date: "2 hours ago",
      thumbnail: "https://cdn.discordapp.com/attachments/1105805279439900714/1341051605293994055/image.png?ex=67ce4c62&is=67ccfae2&hm=f1e9cbfd551342ca0ed987e67b3b5696000fb12d686c31c83fc5a0de073135ea&", // Sample image
    },
  ];

  return (
    <div className="app-container">
      <main className="main-content">
        <h1 className="page-title">News Feed</h1>

        <div className="news-grid">
          {newsItems.map((item) => (
            <NewsCard key={item.id} news={item} />
          ))}
        </div>
      </main>
    </div>
  );
}

// ✅ Updated NewsCard Component with Thumbnail
function NewsCard({ news }) {
  return (
    <div className="news-card">
      {/* Thumbnail Image */}
      <img src={news.thumbnail} alt={news.title} className="news-thumbnail" />

      <div className="news-header">
        <h3 className="news-title">{news.title}</h3>
        <p className="news-date">{news.date}</p>
      </div>
      <div className="news-content">
        <p>{news.content}</p>
      </div>
      <div className="news-footer">
        <a href="/" className="read-more-link">
          Read more
        </a>
      </div>
    </div>
  );
}
