import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Layout() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get("https://theunknowanimegame.xyz/auth/user/me", {
          withCredentials: true,
        });

        if (!response.data) {
          throw new Error("No user data");
        }

        setUser(response.data);
      } catch (error) {
        console.error("User not authenticated:", error);
        navigate("/login"); // Redirect if not logged in
      } finally {
        setLoading(false); // Always stop loading after fetch attempt
      }
    }

    fetchUser();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      document.cookie = "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      await axios.post("https://theunknowanimegame.xyz/auth/logout", {}, { withCredentials: true });
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  if (!user) return null; // Prevents UI flicker if auth fails

  return (
    <div className="app-container">
      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? "sidebar-open" : "sidebar-closed"}`}>
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div className="logo-container">
              <div className="logo-circle">
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/2/24/Vietnam_People%27s_Insignia_emblem.svg/1280px-Vietnam_People%27s_Insignia_emblem.svg.png" alt="" />
              </div>
              <span className="logo-name">Vietnam People's Public Security</span>
            </div>
            <button className="close-button hide-on-desktop" onClick={() => setSidebarOpen(false)}>✕</button>
          </div>
          <nav className="sidebar-nav">
            <a href="/dashboard" className="nav-item">Dashboard</a>
            <a href="/account" className="nav-item">Account</a>
            <a href="/admin" className="nav-item">Admin Panel</a>
          </nav>
        </div>
      </div>

      {/* Overlay */}
      {sidebarOpen && <div className="sidebar-overlay" onClick={() => setSidebarOpen(false)} />}

      {/* Main content */}
      <div className="main-container">
        <header className="header">
          <div className="header-left">
            <button className="menu-button" onClick={() => setSidebarOpen(!sidebarOpen)}>☰</button>
          </div>
          <div className="header-center">
            <div className="logo-circle">
              <img src="https://upload.wikimedia.org/wikipedia/en/c/ca/Vietnam_People%27s_Public_Security_shield.png" alt="" />
            </div>
            <span className="logo-name hide-on-mobile">Vietnam People's Public Security</span>
          </div>
          <div className="header-right">
            <button className="notification-button">🔔</button>
            <div className="user-dropdown">
              <button className="user-profile-button" onClick={() => setShowUserMenu(!showUserMenu)}>
                <div className="avatar">
                  {user.avatar
                    ? <img src={`https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png`} alt="User Avatar" className="avatar" />
                    : user.name.charAt(0)}
                </div>
                <div className="user-info hide-on-mobile">
                  <p className="user-name">{user.discord_name}</p>
                  <p className="user-id">ID: {user.discord_id}</p>
                </div>
                <span className="dropdown-icon">▼</span>
              </button>
              {showUserMenu && (
                <div className="dropdown-menu">
                  <div className="dropdown-item">Profile</div>
                  <div className="dropdown-item">Account Settings</div>
                  <div className="dropdown-separator"></div>
                  <div className="dropdown-item" onClick={handleLogout}>Logout</div>
                </div>
              )}
            </div>
          </div>
        </header>

        {/* Page Content */}
        <main className="main-content">
          <Outlet /> {/* This will render the routed components */}
        </main>
      </div>
    </div>
  );
}
